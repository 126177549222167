const awsmobile = {
  aws_project_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_COGNITO_POOL_ID,
  aws_cognito_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_COGNITO_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_COGNITO_POOL_CLIENT_ID,
  oauth: {
    domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
    scope: ['openid'],
    redirectSignIn: process.env.NEXT_PUBLIC_COGNITO_REDIRECT_SIGNIN,
    redirectSignOut: process.env.NEXT_PUBLIC_COGNITO_REDIRECT_SIGNOUT,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',

  cookieStorage: {
    domain: process.env.NEXT_PUBLIC_COGNITO_COOKIE_STORAGE_DOMAIN,
    secure: process.env.NEXT_PUBLIC_COGNITO_COOKIE_STORAGE_SECURE,
    path: '/',
    expires: 365
  }
};

export default awsmobile;
