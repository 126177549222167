import { App } from '@workgood/types';
import { LITERALS } from './literals';

export * from './literals';

export const ACCEPT_COOKIE = 'accept-cookies';
export const SIGNIN_REDIRECT_KEY = 'workgood-redirect-url';
export const STATS_DISPLAY_THRESHOLD = 0;
export const PRACTICES_DISPLAY_THRESHOLD = 0;
export const CONFIG_KEYS = {
  BLOCKED_SHORTCUTS: 'blocked_shortcuts'
};

export enum View {
  HOME = 'home',
  APP = 'app',
  SHORTCUTS = 'shortcuts',
  COLLECTION = 'collection',
  PRACTICE = 'practice',
  ADD_APPS = 'add_apps',
  EXPLORE = 'explore',
  TEST = 'test'
}

export enum Platform {
  MAC = 'mac',
  WINDOWS = 'win'
}

export enum Difficulty {
  BEGINNER = 'beginner',
  ADVANCED = 'advanced',
  EXPERT = 'expert'
}

export enum SkipCode {
  OTHER = 'OTHER',
  NOT_INTERESTED = 'NOT_INTERESTED',
  ALREADY_KNOW = 'ALREADY_KNOW',
  NOT_WORKING = 'NOT_WORKING'
}

export enum Role {
  ANONYMOUS = 'anonymous',
  USER = 'user',
  PAID = 'paid'
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  ENDED = 'ended',
  PAST_DUE = 'past_due'
}

// Practice constants
export const TARGET_HITS = 2;
export const PRACTICE_SESSION_STATE_KEY = 'wg-practice-state';
export const TAKE_FROM_TOP = {
  0: 2,
  1: 3,
  2: 4,
  3: 5,
  4: 5,
  5: 5
};
export const BUCKET_WEIGHTS = {
  '-1': 0,
  0: 90,
  1: 60,
  2: 50,
  3: 30,
  4: 20,
  5: 10,
  100: 0
};

export const APP_CATEGORIES = [
  {
    title: LITERALS.CATEGORY_HEADS.Office,
    filter: (apps): App[] => apps.filter((app) => app.category === 'Office')
  },
  {
    title: LITERALS.CATEGORY_HEADS.Everyday,
    filter: (apps): App[] =>
      apps.filter((app) =>
        ['spotify', 'google-chrome', 'firefox', 'gmail'].includes(app.slug)
      )
  },
  {
    title: LITERALS.CATEGORY_HEADS.Communication,
    filter: (apps): App[] =>
      apps.filter((app) => app.category === 'Communication')
  },
  {
    title: LITERALS.CATEGORY_HEADS.Browsers,
    filter: (apps): App[] => apps.filter((app) => app.category === 'Browsers')
  },
  {
    title: LITERALS.CATEGORY_HEADS.Coding,
    filter: (apps): App[] => apps.filter((app) => app.category === 'Coding')
  }
  // {
  //   title: LITERALS.CATEGORY_HEADS.Audio,
  //   filter: (apps): App[] => apps.filter((app) => app.category === 'Audio')
  // }
];
