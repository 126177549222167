import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { markRedirect } from 'utils/route';

export const facebookSignIn = (redirectUrl?: string) => {
  if (redirectUrl) markRedirect(redirectUrl);
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Facebook
  });
};

export const googleSignIn = (redirectUrl?: string) => {
  if (redirectUrl) markRedirect(redirectUrl);
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google
  });
};
