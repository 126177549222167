import { AccessControl } from 'role-acl';
import { conditions } from './conditions';
import { GRANTS } from './grants';

export const accessControl = new AccessControl();

for (const key in conditions) {
  accessControl.registerConditionFunction(key, conditions[key]);
}

accessControl.setGrants(GRANTS);
