import decode from 'jwt-decode';

const REFRESH_TOKEN_LEGROOM = 5 * 60;

export const getTokenState = (token?: string | null) => {
  if (!token) {
    return { valid: false, needRefresh: true };
  }

  const decoded = decode(token) as any;
  if (!decoded) {
    return { valid: false, needRefresh: true };
  } else if (decoded.exp && Date.now() + REFRESH_TOKEN_LEGROOM > decoded.exp) {
    return { valid: true, needRefresh: true };
  } else {
    return { valid: true, needRefresh: false };
  }
};
