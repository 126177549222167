import gql from 'graphql-tag';

export const QUERY_INDUSTRIES_ROLES = gql`
  query getIndustries @cached(ttl: 300) {
    Industry {
      name
      Roles {
        name
      }
    }
  }
`;

export const INDUSTRY_ROLE = gql`
  query getIndustryRoles @cached(ttl: 300) {
    __typename ## Placeholder value
    Industry {
      id
      name
      img
      Roles {
        id
        name
        img
      }
    }
  }
`;
