import { Collection, User_Collection, User_Feature } from '@workgood/types';
import gql from 'graphql-tag';

export const USER_INFO = gql`
query {
  User {
    id
    name
    email
    stripe_customer_id
    subscription_status
    credits

    MyCollections {
      app_id
      id
      name

      Collection_Features {
        feature_id
      }
    }

    User_Apps {
      app_id
    }

    Feature_Likes {
      feature_id
    }

  }
}
`;

export const GET_USER_COLLECTIONS = gql`
  query GetUserCollections($user_id: String = "") {
    Collection(where: { user_id: { _eq: $user_id } }) {
      app_id
      id
      name
    }
  }
`;

export const GET_USER_APPS = gql`
  query GetUserApps {
    User_App {
      app_id
    }
  }
`;
export const GET_USER_COLLECTION_LIKES = gql`
  query GetUserApps {
    Collection_Like {
      collection_id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: String = "", $user: user_set_input = {}) {
    update_by_pk_User(pk_columns: { id: $id }, _set: $user) {
      id
      name
      subscription_status
    }
  }
`;

export const USER_COLLECTION = gql`
  query {
    User_Collection {
      Collection {
        id
      }
    }
  }
`;

export const USER_APPS = gql`
  subscription {
    User_App {
      App {
        id
        name
        logo
        slug
        description
        default_collection_id
      }
    }
  }
`;

export const ADD_USER_APP = gql`
  mutation AddUserApp($app_id: uuid = "") {
    insert_User_App_one(object: { app_id: $app_id }) {
      app_id
    }
  }
`;
export const ADD_USER_APPS = gql`
  mutation AddUserApps($objects: [user_app_insert_input!] = {}) {
    insert_User_App(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_USER_APP = gql`
  mutation DeleteUserApp($app_id: uuid = "", $user_id: String = "") {
    delete_User_App(
      where: { app_id: { _eq: $app_id }, user_id: { _eq: $user_id } }
    ) {
      returning {
        app_id
      }
    }
  }
`;

export const GET_MY_APP_COLLECTIONS = gql`
  query getMyAppCollections($user_id: String! = "", $app_id: uuid! = "") {
    Collection(
      where: { user_id: { _eq: $user_id }, app_id: { _eq: $app_id } }
    ) {
      app_id
      id
      name

      Collection_Features_aggregate {
        aggregate {
          count
        }
      }

      Collection_Features {
        feature_id
      }
    }
  }
`;

export const GET_LIKED_COLLECTIONS = gql`
  query GetLikedCollections($user_id: String = "") {
    Collection_Like(where: { user_id: { _eq: $user_id } }) {
      Collection {
        id
        name
      }
    }
  }
`;

export const GET_USER_COLLECTION = gql`
  query getUserCollection($id: uuid = "") {
    User_Collection(where: { collection_id: { _eq: $id } }) {
      id
      last_trained_at
      buckets
      progress
    }
  }
`;

export const GET_USER_COLLECTION_PROGRESS = gql`
  query getUserCollection($id: uuid = "", $platform: String = "win") {
    Collection_by_pk(id: $id) {
      id
      name
      User_Collections {
        last_trained_at
      }
      Collection_Features {
        Feature {
          id
          name
          User_Features(where: { platform: { _eq: $platform } }) {
            feature_id
            level
            skip_code
            updated_at
          }
        }
      }
    }
  }
`;

export interface GetUserCollectionProgressResponse {
  Collection_by_pk: Collection;
}

export const GET_USER_FEATURES = gql`
  query getUserFeatures($feature_ids: [uuid!] = "", $platform: String = "win") {
    User_Feature(
      where: { feature_id: { _in: $feature_ids }, platform: { _eq: $platform } }
    ) {
      level
      feature_id
      id
      platform
      skip_code
      updated_at
    }
  }
`;
export interface GetUseFeaturesResponse {
  User_Feature: User_Feature[];
}

export const INSERT_USER_APP = gql`
  mutation user_app_insert($app_id: uuid) {
    insert_User_App(objects: { app_id: $app_id }) {
      returning {
        app_id
        user_id
      }
    }
  }
`;

export const GET_USER_APP_COLLECTIONS = gql`
  query getUserAppCollections($user_id: String = "", $app_id: uuid = "") {
    User_Collection(
      where: {
        user_id: { _eq: $user_id }
        Collection: { app_id: { _eq: $app_id }, is_favorite: { _eq: false } }
      }
    ) {
      collection_id
    }
  }
`;

// export const INSERT_USER_COLLECTION = gql`
//   mutation insertUserCollection($collection_id: uuid) {
//     insert_User_Collection_one(object: { collection_id: $collection_id }) {
//       Collection {
//         name
//       }
//     }
//   }
// `;

export const DELETE_USER_COLLECTION = gql`
  mutation userCollectionDelete($collection_id: uuid) {
    delete_User_Collection(where: { collection_id: { _eq: $collection_id } }) {
      returning {
        collection_id
      }
    }
  }
`;

export const USER_TRAINING = gql`
  mutation($object: User_Training_insert_input!, $platform: String!) {
    insert_User_Training_one(
      object: $object
      on_conflict: {
        constraint: User_Training_pkey
        update_columns: [platform]
      }
    ) {
      bucket
      stats
      day
      Training {
        App {
          logo
          name
        }
        name
        Exercises(where: { platform: { _eq: $platform } }) {
          id
          initialScreenshot
          Feature {
            name
            mac_binding
            win_binding
          }
          Steps {
            feature_id
            featureStep
            img
            annotationLocation
            Feature {
              name
              mac_binding
              win_binding
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_TRAINING_PROGRESS = gql`
  mutation UpsertUserTraining($object: User_Training_insert_input!) {
    insert_User_Training_one(
      object: $object
      on_conflict: {
        constraint: User_Training_pkey
        update_columns: [bucket, day, status, lastTrainingCompleteDate, stats]
      }
    ) {
      bucket
      stats
      day
    }
  }
`;

export const INSERT_APP_FAVORITES_COLLECTION = gql`
  mutation insertAppFavoritesCollection($app_id: uuid = "") {
    insert_Collection(
      objects: {
        app_id: $app_id
        User_Collections: {
          data: {}
          on_conflict: {
            constraint: User_Collection_pkey
            update_columns: collection_id
          }
        }
        name: "Favorites"
        is_favorite: true
      }
      on_conflict: { constraint: Collection_pkey, update_columns: app_id }
    ) {
      returning {
        id
        name
        User_Collections {
          id
          User {
            name
            id
          }
        }
      }
    }
  }
`;

export const GET_APP_FAVORITE_SHORTCUTS = gql`
  query getAppFavoriteShortcuts($app_id: uuid = "") {
    User_Collection(
      where: {
        Collection: { is_favorite: { _eq: true }, app_id: { _eq: $app_id } }
      }
    ) {
      id
      collection_id
      Collection {
        id
        Collection_Features {
          feature_id
        }
      }
    }
  }
`;
export interface GetAppFavoriteShortcutsResponse {
  User_Collection: User_Collection[];
}

export const INSERT_FAVORITE_SHORTCUT = gql`
  mutation insertFavoriteShortcut(
    $collection_id: uuid = ""
    $feature_id: uuid = ""
  ) {
    insert_Collection_Feature(
      objects: { collection_id: $collection_id, feature_id: $feature_id }
    ) {
      returning {
        collection_id
        feature_id
      }
    }
  }
`;

export const DELETE_FAVORITE_SHORTCUT = gql`
  mutation deleteFavoriteShortcut(
    $collection_id: uuid = ""
    $feature_id: uuid = ""
  ) {
    delete_Collection_Feature(
      where: {
        collection_id: { _eq: $collection_id }
        feature_id: { _eq: $feature_id }
      }
    ) {
      returning {
        feature_id
      }
    }
  }
`;

export const INSERT_USER_COLLECTION = gql`
  mutation insertUserCollection($collection_id: uuid, $buckets: jsonb) {
    insert_User_Collection_one(
      object: { collection_id: $collection_id, buckets: $buckets }
      on_conflict: {
        constraint: user_collection_collection_id_user_id_key
        update_columns: buckets
      }
    ) {
      id
      buckets
      progress
    }
  }
`;

export const SAVE_PRACTICE_PROGRESS = gql`
  mutation savePracticeProgress($id: uuid!, $buckets: jsonb) {
    update_User_Collection_by_pk(
      pk_columns: { id: $id }
      _set: { buckets: $buckets }
    ) {
      buckets
    }
  }
`;

export const SAVE_FEATURE_PROGRESS = gql`
  mutation saveFeatureProgress(
    $feature_id: uuid!
    $level: Int!
    $platform: String = "win"
    $updated_at: timestamptz!
  ) {
    insert_User_Feature_one(
      object: {
        feature_id: $feature_id
        level: $level
        platform: $platform
        updated_at: $updated_at
      }
      on_conflict: {
        constraint: user_feature_user_id_feature_id_platform_key
        update_columns: [level, updated_at]
      }
    ) {
      level
      id
    }
  }
`;

export const BATCH_SAVE_USER_FEATURE = gql`
  mutation batchSaveFeatureProgress(
    $userFeatures: [user_feature_insert_input!]!
  ) {
    insert_User_Feature(
      objects: $userFeatures
      on_conflict: {
        constraint: user_feature_user_id_feature_id_platform_key
        update_columns: [level, updated_at, skip_code]
      }
    ) {
      affected_rows
    }
  }
`;

export const SKIP_FEATURE = gql`
  mutation skipFeature(
    $feature_id: uuid!
    $skip_code: String!
    $platform: String = "win"
  ) {
    insert_User_Feature_one(
      object: {
        feature_id: $feature_id
        skip_code: $skip_code
        platform: $platform
      }
      on_conflict: {
        constraint: user_feature_user_id_feature_id_platform_key
        update_columns: skip_code
      }
    ) {
      skip_code
      feature_id
    }
  }
`;

export const LIKE_FEATURE = gql`
  mutation LikeFeature($feature_id: uuid = "") {
    insert_Feature_Like_one(object: { feature_id: $feature_id }) {
      feature_id
    }
  }
`;

export const UNLIKE_FEATURE = gql`
  mutation UnlikeFeature($feature_id: uuid = "") {
    delete_Feature_Like(where: { feature_id: { _eq: $feature_id } }) {
      returning {
        feature_id
      }
    }
  }
`;

export const LIKE_COLLECTION = gql`
  mutation LikeCollection($collection_id: uuid = "") {
    insert_Collection_Like_one(object: { collection_id: $collection_id }) {
      collection_id
    }
  }
`;

export const LIKE_COLLECTIONS = gql`
  mutation LikeCollections($collections: [collection_like_insert_input!] = []) {
    insert_Collection_Like(
      objects: $collections
      on_conflict: { constraint: collection_like_collection_id_user_id_key }
    ) {
      affected_rows
      returning {
        collection_id
      }
    }
  }
`;

export const UNLIKE_COLLECTION = gql`
  mutation UnlikeCollection($collection_id: uuid = "") {
    delete_Collection_Like(where: { collection_id: { _eq: $collection_id } }) {
      returning {
        collection_id
      }
    }
  }
`;

export const CREATE_COLLECTION = gql`
  mutation CreateCollection($object: collection_insert_input = {}) {
    insert_Collection_one(object: $object) {
      created_at
      id
      name
      description
    }
  }
`;

export const ADD_COLLECTION_FEATURE = gql`
  mutation AddCollectionFeature($object: collection_feature_insert_input = {}) {
    insert_Collection_Feature_one(object: $object) {
      collection_id
      feature_id
    }
  }
`;
export const REMOVE_COLLECTION_FEATURE = gql`
  mutation DeleteCollectionFeature(
    $collection_id: uuid = ""
    $feature_id: uuid = ""
  ) {
    delete_Collection_Feature(
      where: {
        feature_id: { _eq: $feature_id }
        collection_id: { _eq: $collection_id }
      }
    ) {
      returning {
        feature_id
        collection_id
      }
    }
  }
`;

export const REMOVE_COLLECTION = gql`
  mutation RemoveCollection($id: uuid = "") {
    update_by_pk_Collection(
      pk_columns: { id: $id }
      _set: { is_deleted: true }
    ) {
      id
    }
  }
`;

export const ADD_FEATURE_PREVIEW = gql`
  mutation AddFeaturePreview($object: feature_preview_insert_input = {}) {
    insert_Feature_Preview_one(object: $object) {
      id
      file_name
      height
      width
      status
    }
  }
`;
export const UPLOAD_FEATURE_PREVIEW = gql`
  mutation UploadFeaturePreview(
    $file: String = ""
    $feature_id: uuid = ""
    $user_id: String = ""
  ) {
    uploadFeaturePreview(
      file: $file
      feature_id: $feature_id
      user_id: $user_id
    ) {
      filename
      height
      url
      type
      width
    }
  }
`;

export const ADD_PREVIEW_FEEDBACK = gql`
  mutation AddPreviewFeedback($object: preview_feedback_insert_input = {}) {
    insert_Preview_Feedback_one(object: $object) {
      id
      accepted
      reject_reason
    }
  }
`;

export const ADD_PREVEIEW_FEEDBACK = gql`
  mutation AddPreviewFeedback($object: preview_feedback_insert_input = {}) {
    insert_Preview_Feedback_one(object: $object) {
      id
      accepted
      reject_reason
    }
  }
`;
