import { Role, SubscriptionStatus } from 'utils/constants';
import { User } from '@workgood/types';
import { getCanonicalUrl } from 'utils/route';
import { createOgTitle } from 'modules/og/utils';
import { DataParams } from 'modules/og/types';
import { copyTextToClipboard } from './clipboard';

export function findDuplicatesInArray(data = []) {
  const result = [];

  data.forEach((element, index) => {
    if (data.indexOf(element, index + 1) > -1) {
      if (result.indexOf(element) === -1) {
        result.push(element);
      }
    }
  });

  return result;
}

export function isSameArray(array1, array2) {
  const match1 = array1.every((key) => array2.includes(key));
  const match2 = array2.every((key) => array1.includes(key));

  return match1 && match2;
}

export function getArrayDepth(value) {
  if (!Array.isArray(value)) {
    return 0;
  }

  if (!value.length) {
    return 1;
  }

  return 1 + Math.max(...value.map(getArrayDepth));
}

export function nestedValue(mainObject, key) {
  try {
    return key.split('.').reduce((obj, property) => obj[property], mainObject);
  } catch (err) {
    return null;
  }
}

type GetCollectionTitleOptions = {
  collectionName: string;
  appendShortcuts?: boolean;
};
export const getCollectionTitle = ({
  collectionName,
  appendShortcuts
}: GetCollectionTitleOptions) => {
  const shortcutsWord =
    !/shortcuts/gi.test(collectionName) && appendShortcuts ? 'Shortcuts' : '';
  return `${collectionName} ${shortcutsWord}`;
};

export const getRole = (user: User): Role => {
  return user?.subscription_status === SubscriptionStatus.ACTIVE
    ? Role.PAID
    : user?.id
    ? Role.USER
    : Role.ANONYMOUS;
};

export const shareCollection = ({
  view,
  platform,
  app,
  collection
}: DataParams): Promise<'share' | 'clipboard'> => {
  const title = createOgTitle({
    type: view,
    text: collection?.name,
    params: { app, collection, platform }
  });
  const url = getCanonicalUrl();

  if (navigator.share) {
    return navigator
      .share({
        title: title,
        text: title,
        url
      })
      .then(() => 'share');
  } else {
    return copyTextToClipboard(url).then(() => 'clipboard');
  }
};