import React, { useState, createContext, FC } from 'react';
import { Platform } from 'utils/constants';
import { App, Collection } from '@workgood/types';

interface AppContextProviderProps {
  apps?: App[];
  app?: App;
  collection?: Collection;
  collections?: Collection[];
  appLoading: boolean;
  collectionLoading: boolean;
  collectionsLoading: boolean;
  isDrawerOpen: boolean;
  onDrawerOpenChange: (isOpen: boolean) => void;
  children: React.ReactNode;
}

type AppContextProps = {
  apps: App[];
  app: App;
  collection: Collection;
  collections: Collection[];
  appLoading?: boolean;
  collectionLoading?: boolean;
  collectionsLoading?: boolean;
  platform: Platform;
  isDrawerOpen?: boolean;
  isSignImModalOpen?: boolean;
  isPaymentModalOpen?: boolean;
  myApps?: App[];
  myAppsIndex?: Record<string, App>;
  myCollections?: Collection[];
  likedCollections?: Collection[];
  openModal: (modal: 'login' | 'payment') => void;
  onModalClose: () => void;
  onDrawerClose: () => void;
  onDrawerOpen: () => void;
  getApp?: (id: string) => App;
  getCollection?: (id: string) => Collection;
  setApp?: (app: App) => void;
  setCollection?: (collection: Collection) => void;
  setCollections?: (collections: Collection[]) => void;
  setAppLoading?: (loading: boolean) => void;
  setCollectionLoading?: (loading: boolean) => void;
  setCollectionsLoading?: (loading: boolean) => void;
  setMyApps?: (apps: App[]) => void;
  setMyAppsIndex?: (index: Record<string, App>) => void;
  setLikedCollections?: (collections: Collection[]) => void;
  setMyCollections?: (collections: Collection[]) => void;
};

export const AppContext = createContext<Partial<AppContextProps>>({
  app: null,
  collection: null,
  collections: null,
  myApps: [],
  myAppsIndex: {},
  myCollections: [],
  likedCollections: [],
  platform: Platform.WINDOWS,
  isDrawerOpen: false,
  getApp: () => null,
  getCollection: () => null,
  setApp: () => {},
  setCollection: () => {},
  setCollections: () => {},
  setAppLoading: () => {},
  setCollectionLoading: () => {},
  setCollectionsLoading: () => {}
});

export const AppContextProvider: FC<AppContextProviderProps> = ({
  apps,
  app,
  collection,
  collections,
  appLoading,
  collectionLoading,
  collectionsLoading,
  isDrawerOpen,
  onDrawerOpenChange,
  children
}) => {
  const [myApps, setMyApps] = useState([]);
  const [myAppsIndex, setMyAppsIndex] = useState({});
  const [myCollections, setMyCollections] = useState([]);
  const [likedCollections, setLikedCollections] = useState([]);
  const [isSignImModalOpen, setIsSignImModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const getApp = (appId: string): App => apps.find(({ id }) => appId === id);
  const getCollection = (collectionId: string): Collection =>
    collections.find(({ id }) => collectionId === id);
  const openModal = (modal: 'login' | 'payment') => {
    if (modal === 'login') setIsSignImModalOpen(true);
    if (modal === 'payment') setIsPaymentModalOpen(true);
  };
  const onModalClose = () => {
    setIsSignImModalOpen(false);
    setIsPaymentModalOpen(false);
  };

  const state = {
    app,
    collection,
    collections,
    myApps,
    myAppsIndex,
    myCollections,
    likedCollections,
    isDrawerOpen,
    appLoading,
    collectionLoading,
    collectionsLoading,
    isSignImModalOpen,
    isPaymentModalOpen,
    onModalClose,
    platform: Platform.WINDOWS,
    openModal,
    getApp,
    getCollection,
    setMyApps,
    setMyAppsIndex,
    setMyCollections,
    setLikedCollections,
    onDrawerOpen: () => onDrawerOpenChange(true),
    onDrawerClose: () => onDrawerOpenChange(false)
  };

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
};
