import { openDB } from 'idb';
import { schema, dbName, latestVersion } from './schema';

export const getLocalDb = () => {
  // make sure this only runs on browser
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) throw new Error('IndexedDB can be used only in a browser.');
  
  return openDB(dbName, latestVersion, {
    upgrade(db) {
      const stores = schema[`v${latestVersion}`];

      stores.forEach(({ name, id, indices }) => {
        const store = db.createObjectStore(name, id);
        indices?.forEach(({ name, keyPath }) => {
          store.createIndex(name, keyPath);
        });
      });
    }
  });
};
