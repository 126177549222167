import react, { memo, useContext } from 'react';
import { Box, useToast } from '@chakra-ui/react';
import { useUser } from 'modules/auth/use-user';
import router from 'next/router';
import { Permission } from 'role-acl';
import { AppContext } from 'utils/contexts/AppContext';
import { Role } from 'utils/constants';

interface AccessProps {
  children: react.ReactNode;
  action: string;
  redirectUrl?: string;
  resource: string;
  hideOnNoAccess?: boolean;
  onClick?: (param: any) => any;
  context: any;
}

export const Access = memo(({
  children,
  action,
  resource,
  context,
  hideOnNoAccess = false,
  onClick,
  redirectUrl
}: AccessProps) => {
  const { openModal, onModalClose } = useContext(AppContext);
  const toast = useToast();
  const { user, role, isAuthenticated, can } = useUser();

  const access: Permission = can({
    resource,
    action,
    context
  });

  const hasAccess = access?.granted ?? false;

  if (!hasAccess && hideOnNoAccess) {
    return <></>;
  }

  const handleClick = (props) => {
    if (access?.granted && onClick) {
      onClick(props);
      return;
    }

    if (!isAuthenticated) {
      openModal('login');
      return;
    }

    if (role !== Role.PAID) {
      openModal('payment');
      return;
    }

    toast({
      description: 'This user does not have access to this feature.',
      status: 'info',
      duration: 1000,
      isClosable: true
    });
  };

  const handleModalClose = () => {
    onModalClose();
    openModal;
    analytics.track('Practice Modal Closed');
  };
  const handleLoginSuccess = () => {
    onModalClose();
    analytics.track('Login Success');
    router.push(redirectUrl, undefined, {
      shallow: true
    });
  };
  return (
    <>
      <Box onClick={handleClick}>{children}</Box>
    </>
  );
});

export default Access;
