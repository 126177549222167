import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  CircularProgress,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  Flex
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useUser, ModalLogin } from 'modules/auth';

interface VerifyProps {
  username: string;
  password: string;
  onVerifyComplete: () => void;
}

export const Verify: FC<VerifyProps> = ({
  onVerifyComplete,
  username,
  password,
  ...props
}) => {
  const { isAuthenticated, refetch } = useUser();
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // if (isAuthenticated) onVerifyComplete();
  }, [isAuthenticated]);

  const handleVerify = () => {
    setLoading(true);
    Auth.confirmSignUp(username, code)
      .then(async () => {
        setLoading(false);
        await Auth.signIn(username, password);
        await Auth.currentAuthenticatedUser();
        setTimeout(() => {
          setLoading(false);
          onVerifyComplete();
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
        setCode('');
        setError(err?.message);
      });
  };

  return (
    <Box {...props}>
      <Alert status="info" mb={4}>
        <Flex mr={0} alignItems='center'>
          <AlertIcon />A verification code has been sent to your email.{' '}
        </Flex>
        {/* <Button variant='outline' colorScheme={'teal'} size='sm'>Resend</Button> */}
      </Alert>
      <FormControl isRequired>
        <FormLabel>Verification Code</FormLabel>
        <Input
          type="text"
          placeholder="000000"
          value={code}
          onChange={(event) => setCode(event.currentTarget.value)}
        />
      </FormControl>
      {error && (
        <Alert status="error" my={3}>
          <AlertIcon />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <Button
        colorScheme="blue"
        variant="solid"
        width="full"
        mt={4}
        onClick={handleVerify}
      >
        {loading ? (
          <CircularProgress isIndeterminate size="24px" color="teal" />
        ) : (
          'Verify'
        )}
      </Button>
    </Box>
  );
};

export default Verify;
