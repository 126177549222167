import { CustomCondition } from './conditions';

enum GrantResource {
  Feature = 'feature',
  Collection = 'collection',
  CollectionFeature = 'collectionFeature',
  App = 'app'
}
enum GrantAction {
  Star = 'star',
  Unstar = 'unstar',
  Add = 'add',
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
  Practice = 'practice',
  ProPractice = 'pro_practice'
}
enum Grant {
  FeatureLike = 'FeatureLike',
  CollectionFeatureAdd = 'CollectionFeatureAdd',
  CollectionStar = 'CollectionStar',
  CollectionUnstar = 'CollectionUnstar',
  CollectionCreate = 'CollectionCreate',
  CollectionEdit = 'CollectionEdit',
  CollectionDelete = 'CollectionDelete',
  CollectionPractice = 'CollectionPractice',
  CollectionPracticePro = 'CollectionPracticePro',
  AppStar = 'AppStar',
  AppUnstar = 'AppUnstar'
}

const grants: Record<Grant, GrantObj> = {
  FeatureLike: {
    resource: GrantResource.Feature,
    action: GrantAction.Star,
    attributes: ['*']
  },
  CollectionStar: {
    resource: GrantResource.Collection,
    action: GrantAction.Star,
    attributes: ['*']
  },
  CollectionUnstar: {
    resource: GrantResource.Collection,
    action: GrantAction.Unstar,
    attributes: ['*']
  },
  CollectionCreate: {
    resource: GrantResource.Collection,
    action: GrantAction.Create,
    attributes: ['*']
  },
  CollectionEdit: {
    resource: GrantResource.Collection,
    action: GrantAction.Edit,
    attributes: ['*'],
    condition: {
      Fn: 'EQUALS',
      args: {
        user_id: '$.userId'
      }
    }
  },
  CollectionDelete: {
    resource: GrantResource.Collection,
    action: GrantAction.Delete,
    attributes: ['*'],
    condition: {
      Fn: 'EQUALS',
      args: {
        user_id: '$.userId'
      }
    }
  },
  CollectionPractice: {
    resource: GrantResource.Collection,
    action: GrantAction.Practice,
    attributes: ['*'],
    condition: {
      Fn: `custom:${CustomCondition.CAN_PRACTICE_APP}`,
      args: {}
    }
  },
  CollectionPracticePro: {
    resource: GrantResource.Collection,
    action: GrantAction.ProPractice,
    attributes: ['*'],
    condition: {
      Fn: `custom:${CustomCondition.CAN_PRACTICE_PRO_APP}`,
      args: {}
    }
  },
  AppStar: {
    resource: GrantResource.App,
    action: GrantAction.Star,
    attributes: ['*']
  },
  AppUnstar: {
    resource: GrantResource.App,
    action: GrantAction.Unstar,
    attributes: ['*']
  },
  CollectionFeatureAdd: {
    resource: GrantResource.CollectionFeature,
    action: GrantAction.Add,
    attributes: ['*']
  }
};

interface GrantObj {
  resource: GrantResource;
  action: GrantAction;
  attributes: string[];
  condition?: any;
}

const getGrant = (key) => grants[key];
export const ANONYMOUS_GRANTS: Grant[] = [
  Grant.AppStar,
  Grant.AppUnstar,
  Grant.CollectionStar,
  Grant.CollectionUnstar,
  Grant.CollectionPractice,
].map(getGrant);
export const USER_GRANTS: Grant[] = [
  Grant.FeatureLike,
  Grant.CollectionCreate,
  Grant.CollectionEdit,
  Grant.CollectionDelete,
  Grant.CollectionFeatureAdd
].map(getGrant);
export const PAID_GRANTS: Grant[] = [
  Grant.CollectionPracticePro
].map(getGrant);

export const GRANTS = {
  anonymous: { grants: ANONYMOUS_GRANTS },
  user: { grants: [].concat(ANONYMOUS_GRANTS, USER_GRANTS) },
  paid: { grants: [].concat(ANONYMOUS_GRANTS, USER_GRANTS, PAID_GRANTS) }
};