import React, { FC } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';
const Login = dynamic(() => import('./Login'));

interface ModalLoginProps {
  title?: string;
  open: boolean;
  redirectUrl?: string;
  onSuccess?: () => void;
  onClose?: () => void;
}

export const ModalLogin: FC<ModalLoginProps> = ({
  redirectUrl,
  title,
  open,
  onSuccess,
  onClose = () => {}
}) => {
  const handleSuccess = () => {
    onSuccess();
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={open}
        size={'lg'}
        onClose={() => {
          onClose();
        }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent p={{base: 2, md: 6}}>
          {title && <ModalHeader>{title}</ModalHeader>}
          {title && <ModalCloseButton />}
          <ModalBody>
            {open && (
              <Login redirectUrl={redirectUrl} onSuccess={handleSuccess} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalLogin;
