import { Config } from '@workgood/types';
import gql from 'graphql-tag';

export const GET_CONFIG = gql`
  query getConfig($client: String = "", $platform: String = "") {
    Config(where: { client: { _eq: $client }, platform: { _eq: $platform } }) {
      value
      key
    }
  }
`;

export interface GetConfig {
  Config: Config[];
}
