import { App, Collection } from '@workgood/types';
import gql from 'graphql-tag';

export const GET_APP_COLLECTIONS = gql`
  query getAppCollection($id: uuid!) @cached(ttl: 300) {
    App_by_pk(id: $id) {
      name
      logo
      description
      slug
      Collections {
        id
        name
        difficulty
        Collection_Features {
          Feature {
            id
            name
            mac_binding
            win_binding
          }
        }
        User_Collections {
          id
        }
      }
    }
  }
`;
export interface GetAppCollectionRespose {
  App_by_pk: App;
}

export const GET_ALL_COLLECTION_IDS = gql`
  query getAllCollectionIds {
    App {
      slug
      Collections {
        id
        name
        difficulty
      }
    }
  }
`;
export interface GetAllCollectionIds {
  App: App;
}

export const GET_APP_COLLECTIONS_BY_SLUG = gql`
  query getAppCollectionBySlug($slug: String!) {
    App(where: { slug: { _eq: $slug } }) {
      id
      name
      logo
      description
      slug
      is_free
      Collections(limit: 20) {
        id
        app_id
        name
        difficulty
        user_id

        Likes_aggregate {
          aggregate {
            count
          }
        }
        Collection_Features_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export interface GetAppCollectionsBySlugResponse {
  App: App[];
}

export const GET_ALL_COLLECTIONS = gql`
  {
    App(where: { is_published: { _eq: true } }) {
      slug
      is_free
      Collections {
        id
        name
        difficulty
      }
    }
  }
`;
export interface GetAllCollectionsResponse {
  App: App[];
}

export const GET_APP_BY_SLUG = gql`
  query getAppBySlug($slug: String!) @cached(ttl: 86400) {
    App(where: { slug: { _eq: $slug } }) {
      category
      id
      logo
      name
      slug
      description
      is_free
      default_collection_id
      Features {
        id
        name
        category
        mac_binding
        win_binding
      }
    }
  }
`;
export interface GetAppBySlugResponse {
  App: App[];
}

export const GET_APP_FEATURES_BY_SLUG = gql`
  query getAppFeaturesBySlug($slug: String!) @cached(ttl: 300) {
    App(where: { slug: { _eq: $slug } }) {
      id
      name
      logo
      description
      slug
      Features {
        id
        name
        mac_binding
        win_binding
        category
      }
    }
  }
`;
export interface GetAppFeaturesBySlugResponse {
  App: App[];
}

export const GET_APPS = gql`
  query getApps {
    App {
      category
      author
      id
      logo
      name
      slug
      description
      is_free
      default_collection_id

      Collections(
        where: { user_id: { _is_null: true }, name: { _eq: "Top Shortcuts" } }
      ) {
        id
      }
      Features_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export interface GetAppsResponse {
  App: App[];
}

export const ROLE_APP = gql`
  query role_apps($role_id: uuid) {
    Role(where: { id: { _eq: $role_id } }) {
      name
      Role_Apps {
        App {
          category
          id
          logo
          name
          Collections {
            id
            name
            app_id
            difficulty
            Collection_Features {
              Feature {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const APP_LIST = gql`
  query appList @cached(ttl: 300) {
    App {
      category
      id
      logo
      name
      slug
      is_free
      default_collection_id
      Collections {
        id
        name
        app_id
        difficulty
        Collection_Features {
          Feature {
            id
          }
        }
      }
    }
  }
`;

export interface GetLatestAppsAndCollectionsResponse {
  App: App[];
  Collection: Collection[];
}

export const GET_LATEST_APPS_AND_COLLECTIONS = gql`
  query getLatestAppsAndCollections(
    $app: String = "%"
  ) {
    App {
      id
      name
      slug
      category
    }
    Collection(where: { App: { slug: { _eq: $app } } }) {
      id
      name
      difficulty
      App {
        name
        slug
      }
    }
  }
`;
