import Head from 'next/head';
import dynamic from 'next/dynamic';
import { UserContextProvider } from '../modules/auth';
import { PracticeContextProvider } from '../utils/contexts/PracticeContext';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from 'utils/apollo/client';
import { isDev } from 'utils/env';
import { ChakraProvider } from '@chakra-ui/react';
// import { DefaultSeo } from 'next-seo';
// import SEO from 'utils/seo/next.seo.config';
import Fonts from 'utils/theme/components/fonts';
import CookieBanner from 'components/molecules/CookieBanner';
import chakraTheme from 'utils/theme';
import { init as sentryInit } from 'utils/sentry';
import NextNProgress from 'nextjs-progressbar';

if (typeof window !== 'undefined') {
  sentryInit();
}

export default function App({ Component, pageProps = {}, router }) {
  const { initialApolloState } = pageProps;
  const apolloClient = useApollo(initialApolloState);

  return (
    <ApolloProvider client={apolloClient}>
      <NextNProgress color={'#4055A8'} hegight={2} stopDelayMs={50} />
      <UserContextProvider>
        <PracticeContextProvider>
          <ChakraProvider theme={chakraTheme}>
            <Fonts />
            <Head>
              {/* GTM */}
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${
                        isDev ? 'GTM-N2HTXBS' : 'GTM-WZPVNCV'
                      }');
            `
                }}
              ></script>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
              ></meta>
              <meta name="theme-color" content="#19216C"></meta>
              <meta
                name="apple-mobile-web-app-status-bar-style"
                content="#19216C"
              ></meta>
              <link
                rel="icon"
                type="image/png"
                sizes="96x96"
                href="/favicons/favicon.ico"
              />
              <style
                dangerouslySetInnerHTML={{
                  __html: `.windows-hidden-scrollbar::-webkit-scrollbar {
                  display: none;
                }`
                }}
              ></style>
            </Head>
            {/* <DefaultSeo {...SEO} /> */}
            <Component {...pageProps} />
            <CookieBanner />
          </ChakraProvider>
        </PracticeContextProvider>
      </UserContextProvider>
    </ApolloProvider>
  );
}
