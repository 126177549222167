export default {
  baseStyle: {
    container: {
      pt: 0,
      borderTopWidth: 0,
      _last: {
        borderBottomWidth: 0
      }
    },
    panel: {
      px: 0,
      pt: 0,
      pb: 2
    },
    button: {
      px: 0
    }
  }
};
