import { useMemo } from 'react';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';

import { Auth, Hub } from 'aws-amplify';
import { getTokenState } from './getTokenState';
import { configureAmplify } from './configureAmplify';

if (typeof window !== 'undefined') configureAmplify();

let apolloClient;

export const getHeader = async (_Auth?: any) => {
  const auth = _Auth || Auth;
  try {
    const user = await auth.currentAuthenticatedUser();
    let token = user.signInUserSession.idToken.jwtToken;
    const tokenState = getTokenState(token);
    if (token && tokenState.needRefresh) {
      const refreshPromise = auth.currentAuthenticatedUser();

      if (tokenState.valid === false) {
        token = await refreshPromise;
      }
    }
    return {
      Authorization: `Bearer ${token}`
    };
  } catch (error) {
    return {
      'X-Hasura-Role': 'anonymous'
    };
  }
};

function createApolloClient(_headers) {
  let headers = _headers;

  Hub.listen('auth', ({ payload: { event } }) => {
    switch (event) {
      case 'signIn':
        headers = null;
        break;
      case 'signOut':
        headers = null;
        break;
      default:
        break;
    }
  });

  const authLink = setContext(async () => {
    try {
      headers = headers || (await getHeader());
      return { headers };
    } catch (error) {
      // throw Error(error);
    }
  });

  const httpLink = createHttpLink({
    uri:
      typeof window === 'undefined'
        ? process.env.HASURA_API_HOST
        : window?.location?.origin + '/api/graphql' // your URI here...
  });

  const link = httpLink;

  const error = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
      });

    if (
      networkError &&
      networkError.toString().indexOf('not authenticated') === -1
    ) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const authFlowLink = error.concat(authLink.concat(link));

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: authFlowLink,
    cache: new InMemoryCache({
      addTypename: false
    }),
    connectToDevTools: true
  });
}

export function initializeApollo(
  initialState?: any,
  headers?: any
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient(headers);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
