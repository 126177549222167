import Cookies from 'js-cookie';
import kebabCase from 'lodash/kebabCase';
import { App, Collection } from '@workgood/types';
import { getPlatform } from 'utils/device';
import { Platform, SIGNIN_REDIRECT_KEY, View } from 'utils/constants';
import { getCollectionTitle } from 'utils/helpers';
import { createOgTitle } from 'modules/og/utils';
import { DataParams } from 'modules/og/types';

export interface UrlParams {
  platform: Platform;
  appSlug?: string;
  collectionName?: string;
  collectionId?: string;
  category?: string;
  sort?: string;
  action?: string;
  view?: View;
}

export const buildUrl = (params: UrlParams): string => {
  const { appSlug, collectionName, collectionId, platform, action } = params;
  const url = [`/${platform}/shortcuts`];
  if (appSlug) url.push(appSlug);
  if (collectionName && (collectionName === 'all' || !!collectionId))
    url.push(kebabCase(collectionName));
  if (collectionName && collectionId) url.push(collectionId);
  if (action && collectionId) url.push(action);

  return url.join('/');
};

export const parseUrl = (
  query: string | string[],
  pathname?: string
): UrlParams => {
  const params = query instanceof Array ? query : [query];
  const platform = getPlatform(pathname);
  const appSlug = params[0];
  const collectionName = params[1];
  const collectionId = params[2];
  const action = params[3];
  const appIndex = params.indexOf('app');
  const view = getAppView(
    {
      platform,
      appSlug,
      collectionId,
      collectionName,
      action
    },
    pathname
  );

  return {
    platform,
    appSlug,
    collectionId,
    collectionName,
    action,
    view
  };
};

export const getCollectionUrl = (
  app: App,
  collection: Collection,
  platform: Platform
): string => {
  return `/${platform}/shortcuts/${app?.slug}/${kebabCase(collection?.name)}/${
    collection?.id
  }`;
};

export const getPracticeUrl = (
  app: App,
  collection: Collection,
  platform: Platform
): string => {
  return `${getCollectionUrl(app, collection, platform)}/practice`;
};

export const getAppView = (urlParams: UrlParams, pathname: string): View => {
  const { appSlug, collectionId, action, collectionName } = urlParams;

  if (/debug-shortcuts/gi.test(pathname)) {
    return View.TEST;
  }

  if (action === 'practice') {
    return View.PRACTICE;
  }

  if (typeof collectionId === 'string') {
    return View.COLLECTION;
  }

  if (collectionName === 'all') {
    return View.SHORTCUTS;
  }

  if (typeof appSlug === 'string') {
    return View.APP;
  }

  if (pathname === '/apps') {
    return View.ADD_APPS;
  }

  return View.HOME;
};

export const markRedirect = (url?: string) => {
  Cookies.set(SIGNIN_REDIRECT_KEY, url ?? document.location?.href);
};

export const getMarkedRedirect = (): string | undefined => {
  return Cookies.get(SIGNIN_REDIRECT_KEY);
};

export const getCanonicalUrl = (): string => {
  if (typeof document === 'undefined') return '';

  let url = document.location.href;
  const canonicalElement = document.querySelector('link[rel=canonical]');
  if (canonicalElement !== null) {
    url = (canonicalElement as HTMLLinkElement).href;
  }
  return url;
};
