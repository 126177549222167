import { useEffect, useState, createContext, useContext } from 'react';
import {
  ApolloClient,
  ApolloQueryResult,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { User } from '@workgood/types';
import { Hub } from 'aws-amplify';
import { accessControl } from 'modules/shared/acl';
import { Permission } from 'role-acl';
import UserService from 'services/UserService';
import { Role } from 'utils/constants';
import { getLocalUserState, setLocalUserState } from 'utils/cookie';
import { LocalUserState } from 'utils/cookie/types';
import { isServer } from 'utils/env';
import { getRole } from 'utils/helpers';
import {
  localGetLikedCollections,
  localGetUser,
  localGetUserApps,
  localGetUserFeatures
} from 'utils/localDb';

interface AccessLookup {
  resource: string;
  action: string;
  context: any;
}

type UserContextType = {
  cognitoUser: CognitoUser;
  role: Role;
  user: User | null;
  refetch: () => Promise<ApolloQueryResult<UserData>>;
  isAuthenticated: boolean;
  isLoading: boolean;
  localState: LocalUserState;
  can: (lookup: AccessLookup) => Permission;
};

interface UserData {
  User: User[];
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

export interface Props {
  apolloClient?: ApolloClient<NormalizedCacheObject>;
  [propName: string]: any;
}

export const UserContextProvider = (props: Props) => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [user, setUser] = useState<User>(null);
  const [isUserLoading, setIsUserLoading] = useState(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>(null);
  const [isCognitoLoading, setIsCognitoLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const role = getRole(user);
  const localState = getLocalUserState();

  const fetchLocal = () => {
    return localGetUser().then((data) => {
      setUser(data);
      return { User: data };
    }) as Promise<ApolloQueryResult<UserData>>;
  };

  const fetch = (): Promise<ApolloQueryResult<UserData>> => {
    if (isServer) return;
    setIsUserLoading(true);

    if (!isAuthenticated) return fetchLocal();

    return UserService.getUser(client).then((data) => {
      setUser(data);
      setIsUserLoading(false);
    }) as Promise<ApolloQueryResult<UserData>>;
  };

  const commitOfflineData = async () => {
    const userApps = await localGetUserApps();
    const collectionLikes = await localGetLikedCollections();
    const userFeatures = await await localGetUserFeatures();
    // remove `app_id` from User_Feature data
    userFeatures.forEach((uf) => delete (uf as any).app_id);

    /** Commit to API */
    await UserService.addUserApps(
      client,
      userApps.map((ua) => ua.app_id)
    );
    await UserService.likeCollections(client, collectionLikes);
    await UserService.batchSaveFeatureProgress(client, userFeatures);
  };

  useEffect(() => {
    if (!isAuthenticated) {
      fetchLocal();
    } else {
      fetch();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          debugger;
          setCognitoUser(data);
          fetch();
          break;
        case 'signUp':
          commitOfflineData();
          setCognitoUser(data);
          break;
        case 'signOut':
          setCognitoUser(null);
          setIsAuthenticated(false);
          break;
        default:
          break;
      }
    });

    setIsCognitoLoading(true);
    Auth.currentAuthenticatedUser()
      .then((userObj) => {
        setIsAuthenticated(true);
        setIsCognitoLoading(false);
        setCognitoUser(userObj);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setIsCognitoLoading(false);
      });
  }, []);

  const can = ({ resource, action, context }: AccessLookup): Permission => {
    return accessControl
      .can(role)
      .context(context)
      .execute(action)
      .sync()
      .on(resource) as Permission;
  };


  const value = {
    user,
    refetch: fetch,
    role,
    cognitoUser,
    isAuthenticated,
    isLoading: isUserLoading || isCognitoLoading,
    localState,
    can
  };

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};
