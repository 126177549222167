import dynamic from 'next/dynamic';
import { Collection_Like, User, User_App, User_Feature } from '@workgood/types';
import { getLocalDb } from './db';
// let { getLocalDb } = dynamic(() => import('./db'), { ssr: false });
// const getLocalDb = dynamic(() => import('./db', {ssr: false}));

export const localGetUserApps = async (): Promise<User_App[]> => {
  try {
    const db = await getLocalDb();
    return (await db.getAll('User_App')) as User_App[];
  } catch (e) {
    console.error('IndexedDB error, getUserApps:', e);
    return null;
  }
};

export const localAddUserApp = async (app_id: string): Promise<User_App> => {
  try {
    const db = await getLocalDb();
    await db.add('User_App', {
      app_id
    });
    return { app_id } as User_App;
  } catch (e) {
    console.error('IndexedDB error, addUserApp:', e);
    return null;
  }
};

export const localRemoveUserApp = async (app_id: string) => {
  try {
    const db = await getLocalDb();
    return await db.delete('User_App', app_id);
  } catch (e) {
    console.error('IndexedDB error, removeUserApp:', e);
    return null;
  }
};

export const localGetUserFeatures = async (
  app_id?: string
): Promise<User_Feature[]> => {
  try {
    const db = await getLocalDb();
    if (app_id) {
      return (await db.getAllFromIndex(
        'User_Feature',
        'app_id',
        app_id
      )) as User_Feature[];
    } else {
      return await db.getAll('User_Feature');
    }
  } catch (e) {
    console.error('IndexedDB error, getUserFeatures:', e);
    return null;
  }
};

export const localSaveUserFeature = async (
  userFeature: User_Feature
): Promise<User_Feature> => {
  try {
    debugger;
    const db = await getLocalDb();
    const current = await db.get('User_Feature', userFeature?.feature_id);
    await db.delete('User_Feature', userFeature?.feature_id);
    const update = Object.assign({}, current, userFeature) as User_Feature;
    await db.add('User_Feature', update);
    debugger;
    return update;
  } catch (e) {
    console.error('IndexedDB error, saveUserFeature:', e);
    return null;
  }
};

export const localGetLikedCollections = async (): Promise<
  Collection_Like[]
> => {
  try {
    const db = await getLocalDb();
    return (await db.getAll('Collection_Like')) as Collection_Like[];
  } catch (e) {
    console.error('IndexedDB error, getLikedCollections:', e);
    return null;
  }
};

export const localLikeCollection = async (
  collection_id: string
): Promise<Collection_Like> => {
  try {
    const db = await getLocalDb();
    await db.add('Collection_Like', {
      collection_id
    });
    return { collection_id } as Collection_Like;
  } catch (e) {
    console.error('IndexedDB error, likeCollection:', e);
    return null;
  }
};

export const localUnlikeCollection = async (collection_id: string) => {
  try {
    const db = await getLocalDb();
    return await db.delete('Collection_Like', collection_id);
  } catch (e) {
    console.error('IndexedDB error, unlikeCollection:', e);
    return null;
  }
};

export const localGetUser = async (): Promise<User> => {
  try {
    const userApps = await localGetUserApps();
    const collectionLikes = await localGetLikedCollections();
    return {
      User_Apps: userApps,
      Collection_Likes: collectionLikes
    } as User;
  } catch (e) {
    console.error('IndexedDB error, unlikeCollection:', e);
    return null;
  }
};
