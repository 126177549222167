import { App, Collection, User } from '@workgood/types';
import { Role } from 'utils/constants';
import { getRole } from 'utils/helpers';

type Context = {
  user?: User;
  app?: App;
  collection?: Collection;
};

export enum CustomCondition {
  CAN_PRACTICE_APP = 'canPracticeApp',
  CAN_PRACTICE_PRO_APP = 'canPracticeProApp',
  IS_FREE_APP = 'isFreeApp'
}

export const conditions = {
  [CustomCondition.CAN_PRACTICE_APP]: (context: Context) => {
    try {
      const { user, app } = context;
      const role = getRole(user);

      if (app.is_free) {
        return true;
      }

      if (role === Role.PAID) {
        return true;
      } 

      return ~~user?.credits > 0;

    } catch (e) {
      return false;
    }
  },
  [CustomCondition.CAN_PRACTICE_PRO_APP]: (context: Context) => {
    try {
      const { user, app } = context;
      const role = getRole(user);

      if (app.is_free) {
        return true;
      }

      if (role === Role.PAID) {
        return true;
      } 

      return ~~user?.credits > 0;

    } catch (e) {
      return false;
    }
  },
  [CustomCondition.IS_FREE_APP]: (context: Context) => {
    try {
      const { app } = context;
      return app?.is_free ?? false;
    } catch (e) {
      return false;
    }
  }
};
