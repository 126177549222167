import { theme } from '@chakra-ui/react';

import tailwindColors from './tailwindColors';

export const colors = {
  brand: {
    50: '#F8FAFF',
    100: '#BED0F7',
    200: '#98AEEB',
    300: '#7B93DB',
    400: '#647ACB',
    500: '#4055A8',
    600: '#35469C',
    700: '#2D3A8C',
    800: '#19216C',
    900: '#000f1d',
  },
  // gray: tailwindColors.blueGray,
  success: theme.colors.green,
  error: theme.colors.red,
  warning: theme.colors.yellow,
};
