import React, { FC, useState } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  CircularProgress,
  Alert,
  AlertIcon,
  AlertDescription,
  Text,
  Link,
  Box,
  Stack
} from '@chakra-ui/react';
import { Auth } from 'aws-amplify';

interface SignUpProps {
  onSignInClick?: () => void;
  onVerify?: (data: { email: string; password: string }) => void;
  onSuccess?: () => void;
}

export const SignUp: FC<SignUpProps> = ({ onVerify, onSuccess }) => {
  const [isSignUp, setIsSignup] = useState<boolean>(true);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const signUp = () => {
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name: name ?? email,
        nickname: name ?? email
      }
    })
      .then(() => {
        onVerify({ email, password });
        analytics.track('Sign Up Success');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        analytics.track('Sign Up Failed');
        setError(err?.message);
      });
  };

  const signIn = () => {
    Auth.signIn(email, password)
      .then(() => {
        analytics.track('Sign In Success');
        setLoading(false);
        onSuccess();
      })
      .catch((err) => {
        setLoading(false);
        analytics.track('Sign In Failed');
        setError(err?.message);
      });
  };

  const handleLoginAction = () => {
    if (loading) return;
    setError(null);
    setLoading(true);

    if (isSignUp) {
      analytics.track('Sign Up Submit Clicked');
      signUp();
    } else {
      analytics.track('Sign In Submit Clicked');
      signIn();
    }
  };

  return (
    <>
      <Stack spacing="6">
        <FormControl isRequired>
          <FormLabel> Email </FormLabel>
          <Input
            type="email"
            placeholder="name@email.com"
            onChange={(event) => setEmail(event.currentTarget.value)}
          />
        </FormControl>
        <FormControl isRequired mt={6} mb={4}>
          <FormLabel> Password </FormLabel>
          <Input
            type="password"
            placeholder="********"
            onChange={(event) => setPassword(event.currentTarget.value)}
          />
        </FormControl>
        <FormControl display={isSignUp ? 'block' : 'none'}>
          <FormLabel>Name</FormLabel>
          <Input
            type="name"
            placeholder="Full Name"
            onChange={(event) => setName(event.currentTarget.value)}
          />
        </FormControl>
      
      {error && (
        <Alert status="error" my={2}>
          <AlertIcon />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <Box style={{ marginTop: '20px' }}>
        <Button
          colorScheme="blue"
          variant="solid"
          width="full"
          onClick={handleLoginAction}
        >
          {loading ? (
            <CircularProgress isIndeterminate size="24px" color="teal" />
          ) : isSignUp ? (
            'Create account'
          ) : (
            'Sign in'
          )}
        </Button>
      </Box>
      </Stack>
      <Text pt={1}>
        {isSignUp ? 'Already have an account? ' : 'No Account? '}
        <Button
          variant="link"
          size="sm"
          onClick={() => {
            setIsSignup(!isSignUp);
            analytics.track(
              `View ${isSignUp ? 'Sign In' : 'Sign Up'} Form Clicked`
            );
          }}
        >
          {isSignUp ? 'Login' : 'Create one'}
        </Button>
      </Text>
      <Text fontSize="sm" pt={1} mb={3} color="gray.600">
        By clicking {isSignUp ? 'Sign Up' : 'Sign In '}, you agree to our{' '}
        <Link
          color="blue.600"
          isExternal
          href="/terms-of-service"
          onClick={() => {
            analytics.track(`Terms of Service Link Clicked`);
          }}
        >
          Terms of Service
        </Link>
        {', '}
        <Link
          color="blue.600"
          isExternal
          href="/privacy-policy"
          onClick={() => {
            analytics.track(`Privacy Policy Link Clicked`);
          }}
        >
          Privacy Policy
        </Link>
        {', and '}
        <Link
          color="blue.600"
          isExternal
          href="/privacy-policy"
          onClick={() => {
            analytics.track(`Cookie Policy Link Clicked`);
          }}
        >
          Cookie Policy
        </Link>
      </Text>
    </>
  );
};

export default SignUp;
