import { Platform, View } from 'utils/constants';
import { getPlatform } from 'utils/device';
import { DataParams } from '../types';

export const FONTS = [
  // { weight: 100, name: 'Thin' },
  // { weight: 200, name: 'ExtraLight' },
  // { weight: 300, name: 'Light' },
  // { weight: 400, name: 'Regular' },
  { weight: 500, name: 'Medium' },
  // { weight: 600, name: 'SemiBold' },
  { weight: 700, name: 'Bold' }
  // { weight: 800, name: 'ExtraBold' },
  // { weight: 900, name: 'Black' }
];

export const TITLE_TEMPLATES: Record<
  View,
  ({ platform, app, collection }: DataParams) => string
> = {
  [View.APP]: ({ app }) => `${app?.name}`,
  [View.ADD_APPS]: () => `Explore All Apps`,
  [View.COLLECTION]: ({ app, collection }) => {
    const platformName = getPlatform() === Platform.MAC ? 'Mac' : 'Windows';
    const shortcutsWord = /shortcuts/gi.test(collection?.name)
      ? ''
      : 'Shortcuts ';
    return `${collection?.name} ${shortcutsWord}for ${app?.name}`;
  },
  [View.PRACTICE]: ({ collection }) =>
    `Practice Shortcuts for ${collection?.name}`,
  [View.SHORTCUTS]: ({ app }) => `All Shortcuts for ${app?.name}`,
  [View.HOME]: () => `Save Time, Learn Shortcuts`,
  [View.EXPLORE]: () => `Save Time, Learn Shortcuts`,
  [View.TEST]: () => `Save Time, Learn Shortcuts`
};

export const SPECIAL_WORDS = [
  'WorkGood',
  'About',
  'API',
  'CSS',
  'GraphQL',
  'iPhone',
  'iTerm',
  'MDX',
  'UI',
  'USB',
  'UX',
  'VSCode'
];

const DESCRIPTION_TEMPLATES = [
  
  


]