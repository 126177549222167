const base = {
  minWidth: '34px',
  textAlign: 'center',
  fontWeight: 'bolder',
  padding: '2px 4px',
  marginRight: '2px',
  background: 'white',
  boxShadow: '0 2px 1px rgb(0 41 70 / 40%)',
  border: '1px solid rgb(0 41 70 / 40%)',
  color: 'gray.800',
  borderRadius: '6px',
  textTransform: 'uppercase'
};

/* Medium */
const keybuttonBasemd = {
  ...base
};
const keybuttonHiddenmd = {
  ...base,
  color: 'gray.400',
  boxShadow: 'none',
  borderStyle: 'dashed',
};
const keybuttonSuccessmd = {
  ...base,
  color: 'white',
  background: 'green.400',
  boxShadow: '0 3px 0px #68D391',
  border: '1px solid #38A169'
};
const keybuttonFailedmd = {
  ...base,
  color: 'white',
  background: 'red.400',
  boxShadow: '0 3px 0px #FC8181',
  border: '1px solid #E53E3E'
};
const largeKeyButton = {
  minWidth: '56px',
  padding: '12px 16px',
  margin: 'auto 4px',
  fontSize: '24px',
  fontWeight: '800',
  lineHeight: '22px',
  borderWidth: '3px',
};
const smallKeyButton = {
  minWidth: 'auto',
  padding: '1px 2px',
  margin: 'auto'
};
/* Small */
const keybuttonBasesm = { ...base, ...smallKeyButton };
const keybuttonHiddensm = { ...keybuttonHiddenmd, ...smallKeyButton };
const keybuttonSuccesssm = { ...keybuttonSuccessmd, ...smallKeyButton };
const keybuttonFailedsm = { ...keybuttonFailedmd, ...smallKeyButton };
/* Large */
const keybuttonBaselg = { ...base, ...largeKeyButton };
const keybuttonHiddenlg = { ...keybuttonHiddenmd, ...largeKeyButton };
const keybuttonSuccesslg = { ...keybuttonSuccessmd, ...largeKeyButton };
const keybuttonFailedlg = { ...keybuttonFailedmd, ...largeKeyButton };

const listItemBase = {
  color: 'gray.100',
  fontWeight: '500',
  '.listItemText': {
    paddingRight: '8px'
  }
};
export default {
  /** List Item */
  listItemBase,
  listItemActive: {
    ...listItemBase,
    bg: 'brand.500',
    color: 'white',
    borderColor: 'brand.700',
    borderLeftWidth: '8px',
    fontWeight: '700'
  },
  listItemInprogress: {
    ...listItemBase,
    color: 'white',
    borderColor: 'brand.700',
    borderLeftWidth: '8px',
    fontWeight: '700'
  },
  listItemCompleted: {
    ...listItemBase,
    color: 'green.300',
    borderColor: 'brand.700',
    borderLeftWidth: '8px',
    fontWeight: '700'
  },

  /** Keyboard Key */
  keybuttonBasemd,
  keybuttonHiddenmd,
  keybuttonSuccessmd,
  keybuttonFailedmd,
  keybuttonBasesm,
  keybuttonHiddensm,
  keybuttonSuccesssm,
  keybuttonFailedsm,
  keybuttonBaselg,
  keybuttonHiddenlg,
  keybuttonSuccesslg,
  keybuttonFailedlg
};
