import Cookies from 'js-cookie';
import { LOCAL_USER_STATE, USER_STATE_DEFAULTS } from './constants';
import { LocalUserState } from './types';

export const setLocalUserState = (userState: LocalUserState) => {
  const currentState = getLocalUserState() ?? USER_STATE_DEFAULTS;

  // incremental update for hasCollection
  userState.hasCollection = {
    ...currentState.hasCollection,
    ...userState.hasCollection
  };

  const newState = Object.assign({}, currentState, userState);
  Cookies.set(LOCAL_USER_STATE, newState);
};

export const getLocalUserState = (): LocalUserState => {
  try {
    const stateStr = Cookies.get(LOCAL_USER_STATE);
    if (!stateStr) return USER_STATE_DEFAULTS;
    return JSON.parse(stateStr);
  } catch (e) {
    console.error('Failed to parse JSON object for nav state', e);
    return USER_STATE_DEFAULTS;
  }
};
