import { OgTemplateParams } from '../types';

export const createOgImageUrl = ({
  view,
  appSlug,
  appName,
  collectionName
}: OgTemplateParams): any[] => {
  const urlArr = [`https://workgood.io/api/og?view=${view}`];
  if (appName?.length) urlArr.push(`&appName=${appName}`);
  if (appSlug?.length) urlArr.push(`&appSlug=${appSlug}`);
  const shortcutsWord = /shortcuts/gi.test(collectionName) ? '' : 'Shortcuts ';
  if (collectionName?.length) urlArr.push(`&collectionName=${collectionName} ${shortcutsWord}`);
  const url = urlArr.join('&');
  return [
    {
      url,
      alt: 'workgood.io',
      width: 1200,
      height: 630
    }
  ];
};
