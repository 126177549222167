import React, { FC } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Login from './Login';
import { getPlatform } from 'utils/device';

interface PageLoginProps {}

export const PageLogin: FC<PageLoginProps> = () => {
  const handleSuccess = () => {
    window.location.href = `${getPlatform()}/shortcuts/`;
  };

  return (
    <>
      <Flex width="Full" align="center" justifyContent="center" mt={[0, 12]}>
        <Box
          width={['100%', '400px']}
          p={8}
          borderWidth={[0, 1]}
          borderRadius={8}
          boxShadow={['none', 'sm']}
        >
          <Login onSuccess={handleSuccess} />
        </Box>
      </Flex>
    </>
  );
};

export default PageLogin;
