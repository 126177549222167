export const LITERALS = {
  PREMIUM_USER_TAG: 'PRO',
  START_PRACTICE: 'Start Training',

  COLLECTION: 'Cheatsheet',
  COLLECTIONS: 'Cheatsheets',

  SHARE: 'Share',
  SHARE_COPY_SUCCESS: 'Link Copied',

  LIKED_APPS: 'Favorite Apps',
  TOP_SHORTCUTS: 'Top Shortcuts',

  CATEGORY_HEADS: {
    Everyday: 'Everyday Apps',
    Audio: 'Audio Engineering',
    Browsers: 'Browsers and tools',
    Coding: 'Developer Tools',
    Communication: 'Working in Teams',
    Office: 'Popular Office Apps',
    Websites: 'Web Apps and Sites'
  }
};

