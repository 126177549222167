export const dbName = 'wg-db'
export const latestVersion = 1

export const schema = {
  v1: [
    {
      name: 'User_App',
      id: { keyPath: 'app_id' },
      indices: []
    },
    {
      name: 'Collection_Like',
      id: { keyPath: 'collection_id', },
      indices: []
    },
    {
      name: 'User_Collection',
      id: { keyPath: 'collection_id' },
      indices: [{ name: 'buckets', keyPath: 'buckets' }]
    },
    {
      name: 'User_Feature',
      id: { keyPath: 'feature_id' },
      indices: [
        { name: 'app_id', keyPath: 'app_id' },
        { name: 'feature_id', keyPath: 'feature_id' },
        { name: 'level', keyPath: 'level' },
        { name: 'platform', keyPath: 'platform' },
        { name: 'updated_at', keyPath: 'updated_at' }
      ]
    }
  ]
};
