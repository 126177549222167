import { Collection } from '@workgood/types';
import gql from 'graphql-tag';

export const GET_COLLECTION = gql`
  query getCollection($id: uuid!) @cached(ttl: 300) {
    Collection_by_pk(id: $id) {
      id
      name
      difficulty
      user_id
      Collection_Features {
        Feature {
          id
          name
          win_binding
          mac_binding

          Likes_aggregate {
            aggregate {
              count
            }
          }

          Previews {
            file_name
            height
            width
          }

          User_Features_aggregate(where: { skip_code: { _is_null: true } }) {
            aggregate {
              count
            }
          }
        }
      }
    }
  }
`;
export interface GetCollectionResponse {
  Collection_by_pk: Collection;
}

export const GET_COLLECTIONS_BY_APP_ID = gql`
  query getCollectionsByApp($app_id: uuid!) @cached(ttl: 300) {
    Collection(where: { App: { id: { _eq: $app_id } } }) {
      id
      name
      difficulty
      Collection_Features {
        Feature {
          name
          win_binding
          mac_binding
        }
      }
    }
  }
`;

export const GET_COLLECTIONS_BY_SLUG = gql`
  query getCollectionsBySlug($slug: String) @cached(ttl: 300) {
    Collection(where: { App: { slug: { _eq: $slug } } }) {
      id
      name
      difficulty
      Collection_Features {
        Feature {
          name
          win_binding
          mac_binding
        }
      }
      User_Collections {
        user_id
      }
    }
  }
`;

export const SEARCH_COLLECTIONS = gql`
  query SeachCollections($term: String = "", $app_id: uuid = "") {
    Collection(
      where: { name: { _ilike: $term }, app_id: { _eq: $app_id } }
      order_by: { Likes_aggregate: { count: desc } }
      limit: 20
    ) {
      id
      name
      app_id
      Likes_aggregate {
        aggregate {
          count
        }
      }
      Collection_Features_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;
