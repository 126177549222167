import Cookie from 'js-cookie';
import React, { useEffect } from 'react';
import { useToast, Link } from '@chakra-ui/react';
import { ACCEPT_COOKIE } from 'utils/constants';

const CookieBanner = () => {
  const toast = useToast();
  let toastIdRef = React.useRef<any>();

  useEffect(() => {
    const accepts = Cookie.get(ACCEPT_COOKIE);
    if (!accepts) {
      open();
    }
  }, []);

  const handleBannerClose = () => {
    Cookie.set(ACCEPT_COOKIE, 'yes');
  };

  const open = () => {
    toastIdRef.current = toast({
      title: `Cookie Consent`,
      description: (
        <div>
          WorkGood uses cookies to guarantee users a better user experience. By
          continuing to use the site you're agreeing to our{' '}
          <Link
            borderRadius={1}
            onClick={(e) => {
              Cookie.set(ACCEPT_COOKIE, 'yes');
              analytics?.track('Cookie Consent Clicked', {
                location: 'Cookie Banner'
              });
            }}
            href="/cookie-policy"
            target="_blank"
          >
            Cookie Consent
          </Link>
        </div>
      ),
      status: 'info',
      isClosable: true,
      duration: 120000,
      position: 'bottom-right',
      onCloseComplete: handleBannerClose
    });
  };

  function close() {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  }

  return <></>;
};

export default CookieBanner;
