import { View } from "utils/constants";
import { COLLECTION_DESCRIPTION_TEMPLATES, DEFAULT_SITE_DESCRIPTION } from "../constants/descriptions";
import { OgParams } from "../types";

export const createOgDescription = (params: OgParams): string => {
  switch (params.view) {
    case View.COLLECTION:
      const descriptionCount = COLLECTION_DESCRIPTION_TEMPLATES.length;
      const modIndex = (params?.collectionName?.length ?? 0) % descriptionCount;
      return COLLECTION_DESCRIPTION_TEMPLATES[modIndex](params);
      break;
    case View.APP:
    case View.ADD_APPS:
    case View.PRACTICE:
    case View.SHORTCUTS:
    case View.HOME:
    case View.EXPLORE:
    case View.TEST:
      return DEFAULT_SITE_DESCRIPTION;
      break;
  }

}