import { LocalUserState } from "./types";

export const HAS_SAVED_APPS_COOKIE = 'hasSavedApps';
export const HAS_SAVED_COLLECTIONS_COOKIE = 'hasSavedCollections';
export const SKIP_HOME = 'skipHome';
export const LOCAL_USER_STATE = 'localUserState';
export const USER_STATE_DEFAULTS: LocalUserState = {
  hasApps: false,
  hasCollection: {},
  skipHome: false,
  hasSeenTour: false,
};