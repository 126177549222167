import React, { useState, createContext, FC, useEffect } from 'react';
import { Practice, Progress, ProgressUpdate, Run } from 'modules/shared/types';
import { Feature, User_Feature } from '@workgood/types';

type PracticeContextProps = {
  currentFeature?: Feature;
  progress: Progress;
  skippedIds?: string[];
  features?: Feature[];
  userFeatures?: User_Feature[];
  setState: (state: PracticeState) => void;
  reset: () => void;
  updatedProgress: (progressUpdate: ProgressUpdate) => void;
};

type PracticeState = {
  currentFeature?: Feature;
  progress?: Progress;
  skippedIds?: string[];
  features?: Feature[];
  userFeatures?: User_Feature[];
};

export const defaultPracticeContext = {
  currentFeature: null,
  progress: null,
  skippedIds: null,
  features: null,
  userFeatures: null
};

export const PracticeContext = createContext<Partial<PracticeContextProps>>(
  defaultPracticeContext
);
export const PracticeContextProvider: FC = ({ children }) => {
  const [state, setState] = useState<PracticeState>(defaultPracticeContext);

  const updatedProgress = (progressUpdate: ProgressUpdate) => {
    setState({
      progress: {
        inprogress: progressUpdate?.inprogress ?? [],
        completed: progressUpdate?.completed ?? [],
        skipped: progressUpdate?.skipped ?? []
      }
    });
  };

  const reset = () => {
    setState(defaultPracticeContext);
  }
  
  const value = {
    ...state,
    reset,
    updatedProgress,
    setState: (_newPartialState: PracticeState) => {
      setState(Object.assign({}, state, _newPartialState));
    }
  };

  return (
    <PracticeContext.Provider value={value}>
      {children}
    </PracticeContext.Provider>
  );
};
