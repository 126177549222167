import {
  ApolloClient,
  ApolloQueryResult,
  FetchPolicy,
  NormalizedCacheObject
} from '@apollo/client';

import {
  User_Collection,
  Bucket,
  Collection,
  User_Feature,
  CollectionProgress,
  FeatureProgress,
  User,
  Feature_Like,
  Collection_Feature,
  Collection_Insert_Input,
  Collection_Like,
  User_App,
  Feature_Preview,
  UploadFeaturePreview
} from '@workgood/types';
import get from 'lodash/get';
import { setLocalUserState } from 'utils/cookie';
import { getPlatform } from 'utils/device';
import {
  localAddUserApp,
  localRemoveUserApp,
  localLikeCollection,
  localUnlikeCollection,
  localSaveUserFeature,
  localGetUser,
  localGetUserApps,
  localGetLikedCollections
} from 'utils/localDb';
import {
  INSERT_USER_COLLECTION,
  SAVE_PRACTICE_PROGRESS,
  GET_USER_COLLECTION,
  GetUserCollectionProgressResponse,
  GET_USER_COLLECTION_PROGRESS,
  SAVE_FEATURE_PROGRESS,
  SKIP_FEATURE,
  USER_INFO,
  UPDATE_USER,
  LIKE_FEATURE,
  UNLIKE_FEATURE,
  GET_MY_APP_COLLECTIONS,
  ADD_COLLECTION_FEATURE,
  REMOVE_COLLECTION_FEATURE,
  CREATE_COLLECTION,
  LIKE_COLLECTION,
  UNLIKE_COLLECTION,
  ADD_USER_APP,
  DELETE_USER_APP,
  REMOVE_COLLECTION,
  ADD_FEATURE_PREVIEW,
  UPLOAD_FEATURE_PREVIEW,
  ADD_USER_APPS,
  GetUseFeaturesResponse,
  GET_USER_FEATURES,
  LIKE_COLLECTIONS,
  BATCH_SAVE_USER_FEATURE,
  GET_USER_APPS,
  GET_USER_COLLECTION_LIKES,
  GET_USER_COLLECTIONS
} from 'utils/queries';

export class UserService {
  client: ApolloClient<NormalizedCacheObject>;

  constructor(_client: ApolloClient<NormalizedCacheObject>) {
    this.client = _client;
  }

  static async startPractice(
    client: ApolloClient<NormalizedCacheObject>,
    collection: Collection
  ): Promise<User_Collection> {
    try {
      const { data } = await client.mutate({
        mutation: INSERT_USER_COLLECTION,
        variables: { collection_id: collection?.id }
      });
      return data.insert_User_Collection_one as User_Collection;
    } catch {
      return null;
    }
  }

  static async saveProgress(
    client: ApolloClient<NormalizedCacheObject>,
    id: string,
    buckets: Bucket
  ): Promise<Bucket> {
    try {
      const { data } = await client.mutate({
        mutation: SAVE_PRACTICE_PROGRESS,
        variables: { id, buckets }
      });
      return data?.update_User_Collection_by_pk?.buckets as Bucket;
    } catch {
      return null;
    }
  }

  static async saveFeatureProgress(
    client: ApolloClient<NormalizedCacheObject>,
    userFeature: User_Feature
  ): Promise<User_Feature> {
    try {
      const { data } = await client.mutate({
        mutation: SAVE_FEATURE_PROGRESS,
        variables: userFeature
      });
      return data;
    } catch {
      await localSaveUserFeature(userFeature);
      return null;
    }
  }

  static async batchSaveFeatureProgress(
    client: ApolloClient<NormalizedCacheObject>,
    userFeatures: User_Feature[]
  ): Promise<User_Feature> {
    try {
      const { data } = await client.mutate({
        mutation: BATCH_SAVE_USER_FEATURE,
        variables: { userFeatures }
      });
      return data;
    } catch {
      return null;
    }
  }

  static async skipFeature(
    client: ApolloClient<NormalizedCacheObject>,
    userFeature: User_Feature
  ): Promise<User_Feature> {
    try {
      const { data } = await client.mutate({
        mutation: SKIP_FEATURE,
        variables: userFeature
      });
      return data?.insert_User_Feature_one as User_Feature;
    } catch {
      await localSaveUserFeature({
        feature_id: userFeature.feature_id,
        skip_code: userFeature.skip_code
      } as User_Feature);
      return null;
    }
  }

  static async getUser(
    client: ApolloClient<NormalizedCacheObject>
  ): Promise<User> {
    try {
      const { data } = await client.query({
        query: USER_INFO,
        variables: {},
        fetchPolicy: 'no-cache'
      });
      return get(data, 'User.0') as User;
    } catch {
      return await localGetUser();
    }
  }

  static async getUserApps(
    client: ApolloClient<NormalizedCacheObject>,
    cache = true
  ): Promise<User_App[]> {
    try {
      const { data } = await client.query({
        query: GET_USER_APPS,
        variables: {},
      });
      return get(data, 'User_App') as User_App[];
    } catch {
      return await localGetUserApps();
    }
  }

  static async getUserCollections(
    client: ApolloClient<NormalizedCacheObject>,
    user_id: string,
    cache = true
  ): Promise<Collection[]> {
    try {
      const { data } = await client.query({
        query: GET_USER_COLLECTIONS,
        variables: { user_id },
      });
      return get(data, 'Collection') as Collection[];
    } catch {
      return null;
    }
  }

  static async getUserCollectionLikes(
    client: ApolloClient<NormalizedCacheObject>,
    cache = true
  ): Promise<Collection_Like[]> {
    try {
      const { data } = await client.query({
        query: GET_USER_COLLECTION_LIKES,
        variables: {},
      });
      return get(data, 'Collection_Like') as Collection_Like[];
    } catch {
      return await localGetLikedCollections();
    }
  }

  static async getUserCollection(
    client: ApolloClient<NormalizedCacheObject>,
    collectionId: string
  ): Promise<User_Collection> {
    try {
      const { data } = await client.query({
        query: GET_USER_COLLECTION,
        variables: { id: collectionId }
      });
      return get(data, 'User_Collection.0') as User_Collection;
    } catch {
      return null;
    }
  }

  static async getUserFeatures(
    client: ApolloClient<NormalizedCacheObject>,
    featureIds: string[]
  ): Promise<User_Feature[]> {
    const platform = getPlatform();
    const makeUserFeature = (id) => ({
      feature_id: id,
      level: 0,
      platform,
      skip_code: null,
      updated_at: null
    });
    try {
      const { data } = (await client.query({
        query: GET_USER_FEATURES,
        variables: { feature_ids: featureIds, platform }
      })) as ApolloQueryResult<GetUseFeaturesResponse>;
      const userFeatures = data?.User_Feature;

      // Backfill missing userFeatures
      return featureIds.map(
        (fid) =>
          userFeatures.find(({ feature_id }) => feature_id === fid) ??
          makeUserFeature(fid)
      ) as User_Feature[];
    } catch (e) {
      return featureIds.map(makeUserFeature as any) as User_Feature[];
    }
  }

  static async getUserCollectionProgress(
    client: ApolloClient<NormalizedCacheObject>,
    collectionId: string
  ): Promise<CollectionProgress> {
    try {
      const { data } = (await client.query({
        query: GET_USER_COLLECTION_PROGRESS,
        variables: { id: collectionId, platform: getPlatform() }
      })) as ApolloQueryResult<GetUserCollectionProgressResponse>;
      const collectionFeatures =
        get(data, 'Collection_by_pk.Collection_Features') ??
        ([] as User_Feature[]);

      const name = get(data, 'Collection_by_pk.name');
      const lastTrainedAt = get(
        data,
        'Collection_by_pk.User_Collections.0.last_trained_at'
      );
      let learned = 0;
      let mastered = 0;
      const features = (collectionFeatures ?? []).map((cf): FeatureProgress => {
        try {
          const { id, name } = get(cf, 'Feature');
          const defaultUserFeature = { level: 0, updated_at: null };
          const { level, updated_at, skip_code } =
            get(cf, 'Feature.User_Features.0') ??
            (defaultUserFeature as User_Feature);

          if (level > 0 && level < 5) learned++;
          if (level >= 5) mastered++;
          return {
            id,
            name,
            level: level ?? 0,
            skipCode: skip_code,
            updatedAt: updated_at ?? null
          };
        } catch (e) {
          return null;
        }
      }, {});

      return {
        name,
        lastTrainedAt,
        total: collectionFeatures?.length,
        learned,
        mastered,
        features
      };
    } catch {
      return null;
    }
  }

  static async updateUser(
    client: ApolloClient<NormalizedCacheObject>,
    id: string,
    user: User
  ): Promise<User> {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_USER,
        variables: { id, user }
      });
      return get(data, 'update_by_pk_User') as User;
    } catch (e) {
      return null;
    }
  }

  static async likeFeature(
    client: ApolloClient<NormalizedCacheObject>,
    feature_id: string
  ): Promise<Feature_Like> {
    try {
      const { data } = await client.mutate({
        mutation: LIKE_FEATURE,
        variables: { feature_id }
      });
      return get(data, 'insert_Feature_Like_one') as Feature_Like;
    } catch (e) {
      return null;
    }
  }

  static async unlikeFeature(
    client: ApolloClient<NormalizedCacheObject>,
    feature_id: string
  ): Promise<Feature_Like> {
    try {
      const { data } = await client.mutate({
        mutation: UNLIKE_FEATURE,
        variables: { feature_id }
      });
      return get(data, 'delete_Feature_Like') as Feature_Like;
    } catch (e) {
      return null;
    }
  }

  static async likeCollection(
    client: ApolloClient<NormalizedCacheObject>,
    collection_id: string
  ): Promise<Collection_Like> {
    try {
      const { data } = await client
        .mutate({
          mutation: LIKE_COLLECTION,
          variables: { collection_id }
        })
        .then((data) => {
          setLocalUserState({ hasCollection: { [collection_id]: true } });
          return data;
        });
      return get(data, 'insert_Collection_Like_one') as Collection_Like;
    } catch (e) {
      return await localLikeCollection(collection_id);
      return null;
    }
  }

  static async likeCollections(
    client: ApolloClient<NormalizedCacheObject>,
    likes: Collection_Like[]
  ): Promise<Collection_Like> {
    try {
      const { data } = await client
        .mutate({
          mutation: LIKE_COLLECTIONS,
          variables: { likes }
        })
        .then((data) => {
          // setLocalUserState({ hasCollection: { [collection_id]: true } });
          return data;
        });
      return get(data, 'insert_Collection_Like_one') as Collection_Like;
    } catch (e) {
      return null;
    }
  }

  static async unlikeCollection(
    client: ApolloClient<NormalizedCacheObject>,
    collection_id: string
  ): Promise<Collection_Like> {
    try {
      const { data } = await client.mutate({
        mutation: UNLIKE_COLLECTION,
        variables: { collection_id }
      });
      return get(data, 'insert_Collection_Like_one') as Collection_Like;
    } catch (e) {
      await localUnlikeCollection(collection_id);
      return null;
    }
  }

  static async getMyCollections(
    client: ApolloClient<NormalizedCacheObject>,
    user_id: string,
    app_id: string
  ): Promise<Collection[]> {
    try {
      const { data } = await client.mutate({
        mutation: GET_MY_APP_COLLECTIONS,
        variables: { user_id, app_id }
      });
      return get(data, 'Collection') as Collection[];
    } catch (e) {
      return null;
    }
  }

  static async addCollection(
    client: ApolloClient<NormalizedCacheObject>,
    collection: Collection_Insert_Input
  ): Promise<Collection> {
    try {
      const { data } = await client
        .mutate({
          mutation: CREATE_COLLECTION,
          variables: { object: collection }
        })
        .then((data) => {
          setLocalUserState({ hasCollection: { [collection.id]: true } });
          return data;
        });
      return get(data, 'insert_Collection_one') as Collection;
    } catch (e) {
      return null;
    }
  }

  static async addFeatureToCollection(
    client: ApolloClient<NormalizedCacheObject>,
    collectionFeature: Collection_Feature
  ): Promise<Collection_Feature> {
    try {
      const { data } = await client.mutate({
        mutation: ADD_COLLECTION_FEATURE,
        variables: { object: collectionFeature }
      });
      return get(data, 'insert_Collection_Feature_one') as Collection_Feature;
    } catch (e) {
      return null;
    }
  }

  static async removeCollectionFeature(
    client: ApolloClient<NormalizedCacheObject>,
    collection_id: string,
    feature_id: string
  ): Promise<Collection_Feature> {
    try {
      const { data } = await client.mutate({
        mutation: REMOVE_COLLECTION_FEATURE,
        variables: { collection_id, feature_id }
      });
      return get(data, 'DeleteCollectionFeature') as Collection_Feature;
    } catch (e) {
      return null;
    }
  }

  static async addUserApp(
    client: ApolloClient<NormalizedCacheObject>,
    app_id: string
  ): Promise<User_App> {
    try {
      const { data } = await client.mutate({
        mutation: ADD_USER_APP,
        variables: { app_id }
      });
      return get(data, 'insert_User_App_one') as User_App;
    } catch (e) {
      return await localAddUserApp(app_id);
      return null;
    }
  }

  static async addUserApps(
    client: ApolloClient<NormalizedCacheObject>,
    appIds: string[]
  ): Promise<User_App> {
    try {
      if (!appIds.length) return null;
      const objects = appIds.map((app_id) => ({ app_id }));
      const { data } = await client.mutate({
        mutation: ADD_USER_APPS,
        variables: { objects }
      });
      return get(data, 'insert_User_App') as User_App;
    } catch (e) {
      appIds.forEach(localAddUserApp);
      return null;
    }
  }

  static async removeUserApp(
    client: ApolloClient<NormalizedCacheObject>,
    app_id: string,
    user_id: string
  ): Promise<User_App> {
    try {
      const { data } = await client.mutate({
        mutation: DELETE_USER_APP,
        variables: { app_id, user_id }
      });
      return get(data, 'delete_User_App') as User_App;
    } catch (e) {
      await localRemoveUserApp(app_id);
      return null;
    }
  }
  static async removeCollection(
    client: ApolloClient<NormalizedCacheObject>,
    id: string
  ): Promise<Collection> {
    try {
      const { data } = await client.mutate({
        mutation: REMOVE_COLLECTION,
        variables: { id }
      });
      return get(data, 'update_by_pk_Collection') as Collection;
    } catch (e) {
      return null;
    }
  }

  static async addFeaturePreview(
    client: ApolloClient<NormalizedCacheObject>,
    object: Feature_Preview
  ): Promise<Feature_Preview> {
    try {
      const { data } = await client.mutate({
        mutation: ADD_FEATURE_PREVIEW,
        variables: { object }
      });
      return get(data, 'insert_Feature_Preview_one') as Feature_Preview;
    } catch (e) {
      return null;
    }
  }

  static async uploadFeaturePreview(
    client: ApolloClient<NormalizedCacheObject>,
    feature_id: string,
    user_id: string,
    file: string
  ): Promise<UploadFeaturePreview> {
    try {
      const { data } = await client.mutate({
        mutation: UPLOAD_FEATURE_PREVIEW,
        variables: { user_id, feature_id, file }
      });
      return get(data, 'uploadFeaturePreview') as UploadFeaturePreview;
    } catch (e) {
      return null;
    }
  }
}

export default UserService;
