import UAParser from 'ua-parser-js';
import { Platform } from 'utils/constants';

const parser = new UAParser();
export const device = parser.getDevice();
export const isMobile = device.type === 'mobile';
export const isTablet = device.type === 'tablet';
export const isDesktop = device.type !== 'mobile' && device.type !== 'tablet';
export const isMac = () => getPlatform() === Platform.MAC;
export const isWin = () => getPlatform() === Platform.WINDOWS;

export const getPlatformByUa = (): Platform => {
  return ['Mac OS', 'iOS'].includes(parser.getOS().name)
    ? Platform.MAC
    : Platform.WINDOWS;
};

const getUaPlatform = (): Platform =>
  parser.getOS()?.name === 'Mac OS' ? Platform.MAC : Platform.WINDOWS;

export const getPlatform = (pathname?: string): Platform => {
  const macPlatformRegex = /^\/mac\/shortcuts/;

  // Use provided pathname
  if (pathname) {
    const tt = /mac|win/gi.test(pathname)
      ? macPlatformRegex.test(pathname)
        ? Platform.MAC
        : Platform.WINDOWS
      : getUaPlatform();

    return /mac|win/gi.test(pathname)
      ? macPlatformRegex.test(pathname)
        ? Platform.MAC
        : Platform.WINDOWS
      : getUaPlatform();
  }

  // Browser/Client context, use location.pathname
  if (typeof window !== 'undefined') {
    const path = window?.location?.pathname;

    return /mac|win/gi.test(path)
      ? macPlatformRegex.test(path)
        ? Platform.MAC
        : Platform.WINDOWS
      : getUaPlatform();
  }

  // SSR Context default to windows if pathname not provided
  return Platform.WINDOWS;
};
