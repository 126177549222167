import { extendTheme } from '@chakra-ui/react';

import Button from './components/button';
import Badge from './components/badge';
import Input from './components/input';
import Accordion from './components/accordion';
import breakpoints from './foundations/breakpoints';
import fonts from './foundations/fonts';
import layerStyles from './foundations/layerStyles';
import textStyles from './foundations/textStyles';
import { colors } from './foundations/colors';
import { shadows } from './foundations/shadows';
import { spacing } from './foundations/spacing';
import { styles } from './foundations/styles';

export default extendTheme({
  breakpoints,
  fonts,
  styles,
  colors,
  shadows,
  textStyles,
  layerStyles,
  space: spacing,
  components: { Badge, Input, Accordion, Button }
});
