import React, { FC, useState } from 'react';
import { Box, Center, Stack, Button } from '@chakra-ui/react';
import { FaGoogle } from 'react-icons/fa';
import EmailSignUp from './EmailSignUp';
import EmailVerify from './EmailVerify';
import { googleSignIn } from 'utils/auth';
interface SocialLoginProps {
  redirectUrl?: string;
  onSuccess?: () => void;
}

export const Login: FC<SocialLoginProps> = ({
  redirectUrl,
  onSuccess = () => null
}) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const isVerifying = !!email;

  const handleSignupSuccess = ({email, password}) => {
    setEmail(email);
    setPassword(password);
  }
  
  return (
    <>
      <Box my={3} textAlign="left">
        <Stack>
          <Button
            display={isVerifying ? 'none' : 'flex'}
            colorScheme="red"
            leftIcon={<FaGoogle />}
            onClick={() => {
              analytics.track('Google Signin Clicked');
              googleSignIn(redirectUrl);
            }}
          >
            Login with Google
          </Button>
          {/* <Button
            display={isVerifying ? 'none' : 'flex'}
            colorScheme="facebook"
            leftIcon={<FaFacebook />}
            onClick={() => {
              analytics.track('Facebook Signin Clicked');
              facebookSignIn(redirectUrl);
            }}
          >
            Login with Facebook
          </Button> */}
          <Center py={2} display={isVerifying ? 'none' : 'flex'}>
            or
          </Center>
          {!isVerifying ? (
            <EmailSignUp onVerify={handleSignupSuccess} onSuccess={onSuccess} />
          ) : (
            <EmailVerify username={email} password={password} onVerifyComplete={onSuccess} />
          )}
        </Stack>
      </Box>
    </>
  );
};

export default Login;
