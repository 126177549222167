import { OgParams } from '../types';

export const DEFAULT_SITE_DESCRIPTION = '';
export const COLLECTION_DESCRIPTION_TEMPLATES: ((params: OgParams) => string)[] = [
  ({ appName }) =>
  `Never waste time with tedious tasks in ${appName} again! Check out our list of shortcuts to help increase your productivity.`,
  ({ appName, collectionName }) =>
    `Looking to be more productive in ${appName}? Check out these helpful ${collectionName.toLowerCase()} that will help you save time!`,
  ({ appName, collectionName }) =>
    `${collectionName} shortcuts are key to being productive in ${appName}. Check out our list of the most useful ones to save time and increase your productivity.`.replace(
      /shortcuts shortcuts/gi,
      'shortcuts'
    ),
  ({ appName, collectionName }) =>
    `Looking to save time while you work in ${appName}? Check out our collection of ${collectionName} shortcuts that will help you get the job done faster.`.replace(
      /shortcuts shortcuts/gi,
      'shortcuts'
    )
];

